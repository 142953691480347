import uuid from "@/utils/uuid";

const COOKIE_CONSENT_KEY = "cookie-consent";

interface Consents {
  statistics: boolean;
  necessary: true;
  consentId?: string;
}

export default {
  getConsents(): Consents {
    const localStorageContent = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (localStorageContent !== null) {
      return JSON.parse(localStorageContent);
    } else {
      return {
        statistics: false,
        necessary: true,
      };
    }
  },

  setConsents(consents: Consents): Consents {
    const consentObject = Object.assign(
      {
        consentId: uuid(),
      },
      consents
    );

    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(consentObject));

    return consentObject;
  },
};
