import GithubLogo from "@/assets/github-brands.svg";
import LinkedinLogo from "@/assets/linkedin-brands.svg";

export default [
  {
    name: "Github",
    url: "https://github.com/steinarhovland",
    icon: GithubLogo,
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/steinarhovland/",
    icon: LinkedinLogo,
  },
];
