<template>
  <div class="error">
    <CoffeeIcon class="error-icon" />
    <h2>{{ title }}</h2>
    <p>{{ description }}</p>
    <Button>Prøv på nytt</Button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CoffeeIcon from "@/assets/coffee-thin.svg";
import Button from "@/components/Button/Button.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  components: {
    CoffeeIcon,
    Button,
  },
});
</script>

<style lang="scss" scoped>
.error {
  text-align: center;
}

.error-icon {
  max-width: 10rem;
  color: var(--shx-orange);
}
</style>
