<template>
  <Header v-once />
  <main
    id="#maincontent"
    :class="{
      content: true,
    }"
  >
    <router-view />
  </main>
  <Footer v-once />
  <CookieBanner />
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import Footer from "./components/Footer/Footer.vue";
import Header from "./components/Header/Header.vue";
import CookieBanner from "@/components/CookieBanner/CookieBanner.vue";
import router from "./router";

export default defineComponent({
  setup() {
    const isPDF = () => {
      return false;
    };

    const clearLocalStorage = (event: KeyboardEvent) => {
      if (event.key === "Unidentified" && event.altKey) {
        localStorage.clear();
        router.go(0);
      }
    };

    onBeforeMount(() => {
      document.addEventListener("keydown", clearLocalStorage);
    });

    onMounted(() => {
      setTimeout(() => {
        document.removeEventListener("keydown", clearLocalStorage);
      }, 1000);
    });

    return { isPDF };
  },
  components: {
    Footer,
    Header,
    CookieBanner,
  },
});
</script>

<style lang="scss" scoped>
.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  padding: 0 4rem;
  min-height: 30rem;

  @media (max-width: 60rem) {
    padding: 0 2rem;
  }
}
</style>
