<template>
  <li
    :class="{
      'portfolio-item': true,
      'portfolio-item--loaded': loaded,
    }"
  >
    <article class="portfolio-item-card">
      <router-link class="portfolio-item-link" :to="projectPage">
        <img
          class="portfolio-item-preview"
          alt="Vis prosjekt Skikort"
          loading="lazy"
          :height="portfolioItem.preview.height"
          :width="portfolioItem.preview.width"
          :src="portfolioPreviewSrc"
          :srcset="portfolioPreviewSrcSet"
          @load="setLoaded"
        />
        <div class="portfolio-item-description">
          <span class="portfolio-item-title">{{ portfolioItem.title }}</span>
          <span class="portfolio-item-client">{{
            portfolioItem.client.name
          }}</span>
        </div>
      </router-link>
    </article>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from "vue";
import { PortfolioItem } from "./PortfolioTypes";

export default defineComponent({
  props: {
    portfolioItem: {
      type: Object as PropType<PortfolioItem>,
      required: true,
    },
  },
  setup(props) {
    const loaded = ref(false);

    const setLoaded = () => {
      loaded.value = true;
    };

    const portfolioPreviewSrcSet = computed(() => {
      const sizes = [700, 560, 520, 280];

      return sizes
        .map((size) => {
          return `${props.portfolioItem.preview.url}?w=${size}&auto=format ${size}w`;
        })
        .join(", ");
    });

    const portfolioPreviewSrc = computed(() => {
      return `${props.portfolioItem.preview.url}?w=560&auto=format`;
    });

    return {
      projectPage: `/prosjekter/${props.portfolioItem.slug}`,
      portfolioPreviewSrcSet,
      portfolioPreviewSrc,
      setLoaded,
      loaded,
    };
  },
});
</script>

<style lang="scss" scoped>
@mixin onFocus {
  .portfolio-item:hover &,
  .portfolio-item:focus-within & {
    @content;
  }
}

.portfolio-item {
  width: 50%;
  padding: 1rem;
  overflow: hidden;

  @media (min-width: 70rem) {
    padding: 2rem;
  }
}

.portfolio-item-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.portfolio-item-preview {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, filter 0.2s ease-in-out;

  @include onFocus {
    filter: blur(0.5rem);
  }

  .portfolio-item--loaded & {
    opacity: 1;
  }
}

.portfolio-item-link {
  width: 100%;
  overflow: hidden;
  border-radius: 0.5px;

  &:focus {
    box-shadow: 0 0 0 0.5rem #111721, 0 0 0 0.8rem var(--shx-orange);
  }
}

.portfolio-item-title {
  font-size: 2rem;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform: translateX(-2rem);

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @include onFocus {
    transform: translateX(0);
    opacity: 1;
  }
}

.portfolio-item-client {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  @include onFocus {
    opacity: 1;
  }
}

.portfolio-item-description {
  display: flex;
  opacity: 0;
  flex-direction: column;
  position: absolute;
  inset: 0;
  text-align: center;
  justify-content: center;
  background: transparent;

  @include onFocus {
    opacity: 1;
    transition: background 0.2s ease-in-out;
    text-shadow: 0 0 1rem #111721;
    background: rgba(#111721, 0.6);
  }
}
</style>
