<template>
  <div class="cookie-banner-overlay" v-if="!state.consentId">
    <div class="cookie-banner">
      <Logo class="logo" inverted />

      <div class="cookie-banner-content">
        <h1>Denne siden bruker informasjonskapsler</h1>
        <p>
          For å holde oversikt over besøk på siden bruker jeg
          informasjonskapsler.<br />Ved å trykke «Det er greit» godkjenner du
          bruken av cookies
        </p>

        <ul class="cookie-list" v-if="expanded">
          <li class="cookie-list-item">
            <Checkbox
              id="necessary"
              label="Nødvendige"
              checked
              disabled
              v-model="state.necessary"
            />
            <p>
              Noen informasjonskapsler er nødvendige for å huske preferanser
              f.eks. hvilke cookies du samtykket til.
            </p>
          </li>
          <li class="cookie-list-item">
            <Checkbox
              id="statistics"
              name="statistics"
              label="Statistikk"
              checked
              v-model="state.statistics"
            />
            <p>
              Informasjonen brukes utelukkende til forbedring av nettsiden og
              oversikt over antall brukere.
            </p>
          </li>
        </ul>

        <Button @click="acceptCookies">Det er greit</Button>
        <Button link @click="readMore" v-if="!expanded">
          Jeg vil vite mer
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import Logo from "@/components/Logo/Logo.vue";
import Button from "@/components/Button/Button.vue";
import Checkbox from "@/components/Checkbox/Checkbox.vue";
import ConsentService from "@/services/consentService";

export default defineComponent({
  setup() {
    const expanded = ref(false);
    const state = reactive(ConsentService.getConsents());

    const acceptCookies = () => {
      Object.assign(state, ConsentService.setConsents(state));
    };

    const readMore = () => {
      expanded.value = true;
    };

    return {
      state,
      expanded,
      acceptCookies,
      readMore,
    };
  },
  components: {
    Logo,
    Button,
    Checkbox,
  },
});
</script>

<style lang="scss" scoped>
.cookie-banner-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0;
}

.cookie-banner {
  position: fixed;
  inset: auto 0 0 0;
  background: #fff;
  color: var(--shx-blue);
  padding: 8rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.3);

  @media (max-width: 900px) {
    padding: 4rem;
    flex-direction: column;
  }
}

.logo {
  margin-right: 8rem;
  @media (max-width: 900px) {
    margin-bottom: 3rem;
    font-size: 2.5rem;
  }
}

h1 {
  text-align: left;
  color: var(--shx-blue);
  font-size: 1.8rem;
}

p {
  margin: 1rem 0;
  max-width: 70rem;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 3rem 0;
}

.cookie-list-item {
  margin: 1rem 0;
}

label {
  font-weight: 700;
}

::v-deep(.button--link) {
  padding: 0;
  margin: 0;
}
</style>
