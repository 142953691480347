<template>
  <Spinner v-if="state.isLoading" />
  <Article title="Prosjekter" width="full">
    <Error
      v-if="state.errorMessage && !state.isLoading"
      :title="state.errorMessage.title"
      :description="state.errorMessage.description"
    />
    <Portfolio
      :content="state.portfolio"
      v-if="!state.isLoading && !state.errorMessage"
    />
  </Article>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import Portfolio from "@/components/Portfolio/Portfolio.vue";
import PortfolioService from "@/services/portfolio";
import { PortfolioItem } from "@/components/Portfolio/PortfolioTypes";
import Error from "@/components/Error/Error.vue";

interface State {
  portfolio: PortfolioItem[];
  isLoading: boolean;
  errorMessage: {
    title: string;
    description: string;
  } | null;
}

export default defineComponent({
  name: "page-projects",
  setup() {
    const state: State = reactive({
      portfolio: [],
      isLoading: true,
      errorMessage: null,
    });

    PortfolioService.getPortfolio()
      .then((portfolio) => {
        state.portfolio = portfolio;
      })
      .catch((error) => {
        state.errorMessage = {
          title: "Oj da, dette skulle ikke skje",
          description: "Feilen er blitt rapportert, prøv igjen snart",
        };
        console.error("Failed", error.status);
      })
      .finally(() => {
        state.isLoading = false;
      });

    return {
      state,
    };
  },
  components: {
    Error,
    Portfolio,
  },
});
</script>
