
import { defineComponent, onMounted, onBeforeMount } from "vue";
import Footer from "./components/Footer/Footer.vue";
import Header from "./components/Header/Header.vue";
import CookieBanner from "@/components/CookieBanner/CookieBanner.vue";
import router from "./router";

export default defineComponent({
  setup() {
    const isPDF = () => {
      return false;
    };

    const clearLocalStorage = (event: KeyboardEvent) => {
      if (event.key === "Unidentified" && event.altKey) {
        localStorage.clear();
        router.go(0);
      }
    };

    onBeforeMount(() => {
      document.addEventListener("keydown", clearLocalStorage);
    });

    onMounted(() => {
      setTimeout(() => {
        document.removeEventListener("keydown", clearLocalStorage);
      }, 1000);
    });

    return { isPDF };
  },
  components: {
    Footer,
    Header,
    CookieBanner,
  },
});
