<template>
  <ul class="portfolio" ref="grid">
    <PortfolioItem
      v-for="portfolioItem in content"
      :portfolioItem="portfolioItem"
      :key="portfolioItem.slug"
    />
  </ul>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, PropType } from "vue";
import Masonry from "masonry-layout";
import PortfolioItem from "./PortfolioItem.vue";
import { PortfolioItem as PortfolioItemType } from "./PortfolioTypes";

export default defineComponent({
  props: {
    content: {
      type: Array as PropType<PortfolioItemType[]>,
    },
  },
  setup() {
    const grid = ref();

    onMounted(() => {
      new Masonry(grid.value, {
        transitionDuration: 100,
      });
    });

    return { grid };
  },
  components: {
    PortfolioItem,
  },
});
</script>

<style lang="scss" scoped>
.portfolio {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  max-width: 120rem;
  width: 100%;
}
</style>
