export const portfolioQuery = `
  {
    allCasestudy(
      where: { _: { is_draft: false } }
      sort: { publishDate: DESC }
    ) {
      _id
      type
      title
      type
      slug {
        current
      }
      client {
        name
      }
      mainImage {
        asset {
          url
          title
          description
          metadata {
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;

export const portfolioItemQuery = (slug: string): string => {
  return `
    {
    allCasestudy(
      where: {
        slug: {
          current: {
            eq: "${slug}"
          }
        }
      }
    ) {
      title
      type
      slug {
        current
      }
      client {
        name
        website
        descriptionRaw
        logo {
          asset {
            url
          }
        }
      }
      content {
        layout {
          value
        }
        image {
          asset {
            url
            metadata {
              dimensions {
                width
                height
              }
            }
            title
            description
          }
        }
        descriptionRaw
        _key
      }
    }
  }
  `;
};
