
import { defineComponent } from "vue";
import Logo from "@/components/Logo/Logo.vue";

import socialAccounts from "@/data/socialAccounts";

export default defineComponent({
  setup() {
    return {
      fullname: process.env.VUE_APP_FULL_NAME,
      socialAccounts,
    };
  },
  components: {
    Logo,
  },
});
