<template>
  <span
    :class="{
      logo: true,
      'logo--inverted': inverted,
    }"
  >
    {{ firstName }}<span class="logo--bold">{{ lastName }}</span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "logo",
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      firstName: process.env.VUE_APP_FIRSTNAME,
      lastName: process.env.VUE_APP_LASTNAME,
    };
  },
});
</script>

<style lang="scss" scoped>
.logo {
  white-space: nowrap;
  font-size: 2rem;
  line-height: 1;
  color: var(--shx-white);
  text-transform: uppercase;
  display: inline-block;

  @media (min-width: 7.68rem) {
    font-size: 3rem;
  }
}

.logo--bold {
  font-weight: 700;
}

.logo--inverted {
  color: var(--shx-blue);
}
</style>
