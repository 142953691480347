<template>
  <footer>
    <Logo class="logo" />
    <ul class="footer-social-link-list">
      <li
        v-for="socialAccount in socialAccounts"
        :key="socialAccount.name"
        class="footer-social-item"
      >
        <a
          class="footer-social-link"
          :href="socialAccount.url"
          rel="nofollow noopener noreferrer"
          :aria-label="`Open my ${socialAccount.name} profile in a new tab`"
        >
          <component :is="socialAccount.icon" class="footer-social-link-icon" />
        </a>
      </li>
    </ul>
    <ul class="footer-link-list">
      <li class="footer-link-item">
        <router-link class="footer-link" to="/cookies">Cookies</router-link>
      </li>
      <li class="footer-link-item">
        <router-link class="footer-link" to="/privacy-policy">
          Privacy policy
        </router-link>
      </li>
    </ul>
    <p class="copyright">
      {{ fullname }} &copy; 1988-{{ new Date().getFullYear() }}
      | All rights reserved
    </p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/components/Logo/Logo.vue";

import socialAccounts from "@/data/socialAccounts";

export default defineComponent({
  setup() {
    return {
      fullname: process.env.VUE_APP_FULL_NAME,
      socialAccounts,
    };
  },
  components: {
    Logo,
  },
});
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 3rem 2rem 3rem;
  flex-shrink: 0;
  @include hide-print;
}

.logo {
  margin: 4rem 2rem;
}

.footer-link-list,
.footer-social-link-list {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
}

.footer-social-link-list {
  margin-bottom: 3rem;
}

.footer-link-item,
.footer-social-link {
  margin: 0 1rem;
}

.footer-link {
  color: var(--shx-text);
  text-decoration-thickness: 0.2rem;
  text-underline-offset: 0.3rem;
}

.footer-social-link {
  color: var(--shx-white);

  &:focus,
  &:hover {
    color: var(--shx-orange);
  }
}

.footer-social-link-icon {
  height: 3rem;
  width: auto;
}

.copyright {
  margin-top: 6rem;
  font-size: 1.3rem;
  @include hide-print;
}

.footer-link-list {
  @include hide-print;
}
</style>
