
import { defineComponent } from "vue";
import CoffeeIcon from "@/assets/coffee-thin.svg";
import Button from "@/components/Button/Button.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  components: {
    CoffeeIcon,
    Button,
  },
});
