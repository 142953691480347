<template>
  <article :class="{ page: true, [`page-width--${width}`]: true }">
    <teleport to="head">
      <title>{{ title }} | Steinar Hovland</title>
    </teleport>

    <h1 class="page-title" v-if="title">{{ title }}</h1>
    <slot />
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { validateProp } from "@/utils/propValidator";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
      default: "narrow",
      validator: (value: string) => {
        return validateProp(["full", "narrow"], value, "width");
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
}

.page-width--narrow {
  max-width: 70rem;
}

.page-title {
  width: 100%;
  @media screen {
    margin-bottom: 6rem;
  }
}
</style>
