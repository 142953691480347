import { GalleryItem } from "@/types/GalleryTypes";
import { portfolioQuery, portfolioItemQuery } from "./queries/portfolio";
import { transformPortfolio } from "@/utils/dataTransformers";
import { http } from "./http";
import { PortfolioItem } from "@/components/Portfolio/PortfolioTypes";

export default {
  getPortfolio(): Promise<PortfolioItem[]> {
    return http(portfolioQuery).then((response) =>
      transformPortfolio(response.data.allCasestudy)
    );
  },
  getPortfolioPiece(slug: string): Promise<GalleryItem> {
    return http(portfolioItemQuery(slug)).then((response) => {
      return response.data.allCasestudy[0];
    });
  },
};
