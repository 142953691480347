import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Projects from "@/pages/Projects.vue";

const routes: Array<RouteRecordRaw> = [
  { path: "/", redirect: "/prosjekter/" },
  {
    name: "Prosjekter",
    path: "/prosjekter/",
    component: Projects,
  },
  {
    name: "Prosjekt",
    path: "/prosjekter/:slug",
    component: () =>
      import(/* webpackChunkName: "project" */ "../pages/Project.vue"),
  },
  {
    name: "Om meg",
    path: "/om-meg/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/About.vue"),
  },
  {
    name: "CV",
    path: "/print/cv",
    component: () => import(/* webpackChunkName: "cv" */ "../pages/CV.vue"),
  },
  {
    name: "Kontakt",
    path: "/kontakt/",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../pages/Contact.vue"),
  },
  {
    name: "KontaktBekreftelse",
    path: "/kontakt/bekreftelse",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../pages/ContactConfirmation.vue"
      ),
  },
  {
    name: "Cookies",
    path: "/cookies",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "../pages/Cookies.vue"),
  },
  {
    name: "Privacy policy",
    path: "/privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../pages/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
