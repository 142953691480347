export const validateProp = (
  permittedValues: string[],
  submittedValue: string,
  propName: string
): boolean => {
  const isValid = permittedValues.includes(submittedValue);

  if (!isValid) {
    console.error(
      `Prop ${propName} is incorrect, valid values are: ${permittedValues.join(
        ", "
      )}`
    );
  }

  return isValid;
};
