export default [
  {
    label: "Prosjekter",
    to: "/prosjekter/",
  },
  {
    label: "Om meg",
    to: "/om-meg/",
  },
  {
    label: "Kontakt",
    to: "/kontakt/",
  },
];
