<template>
  <nav class="menu">
    <ul class="menu-list">
      <MenuItem
        v-for="menuItem in menuItems"
        :to="menuItem.to"
        :key="menuItem.to"
      >
        {{ menuItem.label }}
      </MenuItem>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MenuItem from "./MenuItem.vue";
import menuItems from "./MenuItems";

export default defineComponent({
  setup() {
    return {
      menuItems,
    };
  },
  components: {
    MenuItem,
  },
});
</script>

<style lang="scss" scoped>
.menu {
  @media print {
    display: none;
  }
}
.menu-list {
  display: flex;
  margin: 0;
}
</style>
