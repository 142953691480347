<template>
  <div class="spinner">
    <div class="spinner-block">
      <div class="spinner-item"></div>
      <div class="spinner-item"></div>
      <div class="spinner-item"></div>
    </div>
    <span class="spinner-text">Laster innholdet...</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup(props) {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  display: table;
  display: inline-block;
}

.spinner-text {
  font-size: 2rem;
}

.spinner-block {
  --size: 7.5rem;
  --clr1: var(--shx-white);
  --animation-duration: 3000ms;
  position: relative;
  width: var(--size);
  height: var(--size);
  transform: rotate(45deg);
  margin: 0 auto;

  .spinner-item {
    --item-size: calc(var(--size) / 2.5);
    position: absolute;
    width: var(--item-size);
    height: var(--item-size);
    border: 0.5rem solid var(--clr-spinner);
  }

  .spinner-item:nth-child(1) {
    --clr-spinner: var(--shx-white);
    top: 0;
    left: 0;
    animation: spinneranimation var(--animation-duration) linear infinite;

    @keyframes spinneranimation {
      0%,
      8.33%,
      16.66%,
      100% {
        transform: translate(0%, 0%);
      }

      24.99%,
      33.32%,
      41.65% {
        transform: translate(100%, 0%);
      }

      49.98%,
      58.31%,
      66.64% {
        transform: translate(100%, 100%);
      }

      74.97%,
      83.30%,
      91.63% {
        transform: translate(0%, 100%);
      }
    }
  }

  .spinner-item:nth-child(2) {
    --clr-spinner: var(--clr1);
    top: 0;
    left: var(--item-size);
    animation: spinneranimation2 var(--animation-duration) linear infinite;

    @keyframes spinneranimation2 {
      0%,
      8.33%,
      91.63%,
      100% {
        transform: translate(0%, 0%);
      }

      16.66%,
      24.99%,
      33.32% {
        transform: translate(0%, 100%);
      }

      41.65%,
      49.98%,
      58.31% {
        transform: translate(-100%, 100%);
      }

      66.64%,
      74.97%,
      83.30% {
        transform: translate(-100%, 0%);
      }
    }
  }

  .spinner-item:nth-child(3) {
    --clr-spinner: var(--clr1);
    top: var(--item-size);
    left: var(--item-size);
    animation: spinneranimation3 var(--animation-duration) linear infinite;

    @keyframes spinneranimation3 {
      0%,
      83.30%,
      91.63%,
      100% {
        transform: translate(0, 0);
      }

      8.33%,
      16.66%,
      24.99% {
        transform: translate(-100%, 0);
      }

      33.32%,
      41.65%,
      49.98% {
        transform: translate(-100%, -100%);
      }

      58.31%,
      66.64%,
      74.97% {
        transform: translate(0, -100%);
      }
    }
  }
}
</style>
