<template>
  <div>
    <input
      type="checkbox"
      v-model="state"
      :id="id"
      :name="name || id"
      :disabled="disabled"
      :checked="checked"
      @change="$emit('update:modelValue', state)"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const state = ref(props.checked);

    return { state };
  },
});
</script>

<style lang="scss" scoped>
label {
  position: relative;
  font-weight: 500;
  margin-left: 5rem;
  display: inline-block;
  user-select: none;
}

input {
  height: 4rem;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 4rem;
}

input + label::before {
  background: #fff;
  border: 0.2rem solid var(--shx-blue);
  content: "";
  width: 3rem;
  height: 3rem;
  border-radius: 0.3rem;
  display: block;
  left: -5rem;
  position: absolute;
  top: -0.4rem;
  cursor: pointer;
}

input:disabled + label::before {
  background: #ccc;
}

input:checked + label {
  &::after {
    display: block;
    content: "";
    width: 1rem;
    height: 2rem;
    left: -4rem;
    border-right: 0.3rem solid var(--shx-blue);
    border-bottom: 0.3rem solid var(--shx-blue);
    transform: rotate(45deg);
    position: absolute;
    top: -0.1rem;
  }
}
</style>
