
import { defineComponent, reactive, ref } from "vue";
import Logo from "@/components/Logo/Logo.vue";
import Button from "@/components/Button/Button.vue";
import Checkbox from "@/components/Checkbox/Checkbox.vue";
import ConsentService from "@/services/consentService";

export default defineComponent({
  setup() {
    const expanded = ref(false);
    const state = reactive(ConsentService.getConsents());

    const acceptCookies = () => {
      Object.assign(state, ConsentService.setConsents(state));
    };

    const readMore = () => {
      expanded.value = true;
    };

    return {
      state,
      expanded,
      acceptCookies,
      readMore,
    };
  },
  components: {
    Logo,
    Button,
    Checkbox,
  },
});
