
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SHXButton",
  props: {
    tag: {
      type: String as PropType<"link" | "button">,
      default: "button",
      required: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<"grey" | "green">,
      default: "green",
    },
    size: {
      type: String as PropType<"small" | "medium">,
      default: "medium",
    },
  },
  setup(props) {
    return {};
  },
});
