
import { defineComponent, onMounted, ref, PropType } from "vue";
import Masonry from "masonry-layout";
import PortfolioItem from "./PortfolioItem.vue";
import { PortfolioItem as PortfolioItemType } from "./PortfolioTypes";

export default defineComponent({
  props: {
    content: {
      type: Array as PropType<PortfolioItemType[]>,
    },
  },
  setup() {
    const grid = ref();

    onMounted(() => {
      new Masonry(grid.value, {
        transitionDuration: 100,
      });
    });

    return { grid };
  },
  components: {
    PortfolioItem,
  },
});
