
import { defineComponent } from "vue";
import { validateProp } from "@/utils/propValidator";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
      default: "narrow",
      validator: (value: string) => {
        return validateProp(["full", "narrow"], value, "width");
      },
    },
  },
});
