
import { defineComponent, PropType, computed, ref } from "vue";
import { PortfolioItem } from "./PortfolioTypes";

export default defineComponent({
  props: {
    portfolioItem: {
      type: Object as PropType<PortfolioItem>,
      required: true,
    },
  },
  setup(props) {
    const loaded = ref(false);

    const setLoaded = () => {
      loaded.value = true;
    };

    const portfolioPreviewSrcSet = computed(() => {
      const sizes = [700, 560, 520, 280];

      return sizes
        .map((size) => {
          return `${props.portfolioItem.preview.url}?w=${size}&auto=format ${size}w`;
        })
        .join(", ");
    });

    const portfolioPreviewSrc = computed(() => {
      return `${props.portfolioItem.preview.url}?w=560&auto=format`;
    });

    return {
      projectPage: `/prosjekter/${props.portfolioItem.slug}`,
      portfolioPreviewSrcSet,
      portfolioPreviewSrc,
      setLoaded,
      loaded,
    };
  },
});
