
import { defineComponent } from "vue";
import MenuItem from "./MenuItem.vue";
import menuItems from "./MenuItems";

export default defineComponent({
  setup() {
    return {
      menuItems,
    };
  },
  components: {
    MenuItem,
  },
});
