
import { defineComponent } from "vue";

export default defineComponent({
  name: "logo",
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      firstName: process.env.VUE_APP_FIRSTNAME,
      lastName: process.env.VUE_APP_LASTNAME,
    };
  },
});
