
import { defineComponent } from "vue";
import Logo from "@/components/Logo/Logo.vue";
import Menu from "@/components/Menu/Menu.vue";

export default defineComponent({
  components: {
    Logo,
    Menu,
  },
});
