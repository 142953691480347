<template>
  <component
    :is="tag"
    :class="{ button: true, 'button--link': link, [`button--${color}`]: color }"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SHXButton",
  props: {
    tag: {
      type: String as PropType<"link" | "button">,
      default: "button",
      required: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<"grey" | "green">,
      default: "green",
    },
    size: {
      type: String as PropType<"small" | "medium">,
      default: "medium",
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  font-size: 1.8rem;
  color: var(--shx-blue);
  background-color: #4fc59e;
  background: #97e9cd;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.3rem;
  margin: 1rem 0;
  align-self: start;
  font-weight: 600;
  line-height: 1;
  box-shadow: 0 0.3rem 0 0 #12b17c;
  text-decoration: none;
  display: inline-block;
  outline: none;

  &:focus,
  &:hover {
    background-color: #89ffd7;
    color: var(--shx-blue-4);
  }

  &:active {
    transform: translateY(0.1rem);
  }
}

.button--grey {
  color: #fff;
  background: var(--shx-blue);
  box-shadow: none;

  &:focus,
  &:hover {
    background-color: #4fc59e;
    color: var(--shx-blue-4);
  }
}

:deep(svg) {
  max-height: 2.5rem;
  width: auto;
  vertical-align: -0.5rem;
  margin-left: -0.7rem;
  margin-right: 0.9rem;
}

.button--link {
  background: transparent;
  box-shadow: none;
  text-decoration: underline;

  &:focus,
  &:hover {
    background-color: transparent;
  }
}

.button + .button {
  margin-left: 1.5rem;
}
</style>
