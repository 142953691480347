import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Article from "@/components/Article/Article.vue";
import Spinner from "@/components/Spinner/Spinner.vue";
import "@/css/main.scss";

const app = createApp(App);
app.use(router).mount("#app");
app.component("Article", Article);
app.component("Spinner", Spinner);
