<template>
  <li class="shx-menu-item">
    <router-link class="shx-menu-item-link" :to="to">
      <slot />
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.shx-menu-item {
  padding: 0 1.5rem;
  line-height: 1;

  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }
}

.router-link-active {
  font-weight: 700;
}

.shx-menu-item-link {
  color: var(--shx-white);
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;

  &:hover,
  &:focus {
    border-bottom: 0.3rem solid var(--shx-orange);
  }
}
</style>
