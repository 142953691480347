import { PortfolioItem } from "@/components/Portfolio/PortfolioTypes";
import { GalleryItem } from "@/types/GalleryTypes";
import {
  Certification,
  Education,
  Experience,
  VolunteerWork,
  ResumeItem,
} from "@/types/ResumeTypes";

export const transformEducation = (content: Education[]): ResumeItem[] => {
  const degrees = content.map((degree) => {
    const { _id, title, startDate, endDate, institution } = degree;

    return {
      id: _id,
      title,
      from: startDate,
      to: endDate,
      entity: institution,
    };
  });

  return degrees;
};
export const transformCertifications = (
  content: Certification[]
): ResumeItem[] => {
  const certifications = content.map((certification) => {
    const { _id, title, courseUrl, certifiedDate, certificationAuthority } =
      certification;

    return {
      id: _id,
      title: title,
      from: certifiedDate,
      courseUrl,
      entity: certificationAuthority,
    };
  });

  return certifications;
};

export const transformWorkExperience = (
  content: Experience[]
): ResumeItem[] => {
  const jobs = content.map((job) => {
    const { _id, title, company, startDate, endDate } = job;
    return {
      id: _id,
      title,
      from: startDate,
      to: endDate,
      entity: company,
    };
  });

  return jobs;
};

export const transformVolunteerWork = (
  content: VolunteerWork[]
): ResumeItem[] => {
  const jobs = content.map((job) => {
    const { _id, title, organization, startDate, endDate } = job;
    return {
      id: _id,
      title,
      from: startDate,
      to: endDate,
      entity: organization,
    };
  });

  return jobs;
};

export const transformPortfolio = (content: GalleryItem[]): PortfolioItem[] => {
  const portfolioItems = content.map((portfolioPiece) => {
    const {
      title,
      client: { name: clientName },
      mainImage: {
        asset: {
          url,
          metadata: {
            dimensions: { height, width },
          },
        },
      },
      slug: { current: slug },
    } = portfolioPiece;

    return {
      title,
      client: {
        name: clientName,
      },
      preview: {
        height,
        url,
        width,
      },
      slug,
    };
  });

  return portfolioItems;
};
