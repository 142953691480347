<template>
  <a href="#maincontent" className="jump-to-content"> Hopp til hovedinnhold </a>
  <header>
    <router-link to="/prosjekter" class="logo-link">
      <Logo />
    </router-link>
    <Menu />
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/components/Logo/Logo.vue";
import Menu from "@/components/Menu/Menu.vue";

export default defineComponent({
  components: {
    Logo,
    Menu,
  },
});
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--size-max-width);
  width: 100%;
  margin: 0 auto;
  padding: 4rem;

  @media (max-width: 76.8rem) {
    flex-direction: column;
  }
}

.logo-link {
  &:focus span,
  &:hover span {
    color: var(--shx-orange);
  }
}

::v-deep(.logo) {
  margin-bottom: 4rem;
  @media (min-width: 76.8rem) {
    margin: 4rem 0;
  }
  @media print {
    margin: 0;
  }
}

.jump-to-content {
  @include sr-only;

  &:focus {
    color: #000;
    font-size: 1.8rem;
    position: absolute;
    top: 2rem;
    padding: 2rem;
    white-space: nowrap;
    left: 0;
    background: var(--shx-white);
    display: table;
    max-width: 25rem;
    height: auto;
    overflow: visible;
    clip: auto;
  }
}
</style>
